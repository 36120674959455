var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column" },
    [
      _vm.showForm
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("CategoryTitle", {
                staticClass: "mt-0 mb-6 my-lg-6",
                attrs: { category: _vm.category }
              }),
              !_vm.showNewPasswordPanel
                ? _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                    [
                      _c("v-card-title", { staticClass: "px-0" }, [
                        _vm._v(
                          " Inserisci il codice che hai ricevuto via mail o via messaggio"
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-2 mb-7" },
                        [
                          _c("ResponseMessage", {
                            attrs: { response: _vm.message }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  attrs: { "lazy-validation": _vm.lazy },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                    }
                                  },
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Codice di sicurezza *",
                                      required: "",
                                      outlined: "",
                                      dense: "",
                                      clearable: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.checkToken.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.token,
                                      callback: function($$v) {
                                        _vm.token = $$v
                                      },
                                      expression: "token"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 px-sm-4",
                          attrs: { cols: "12", sm: "4" }
                        },
                        [
                          _c(
                            "v-card-actions",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "reset-button",
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    block: _vm.$vuetify.breakpoint.xs,
                                    "min-width": "200",
                                    height: "40",
                                    depressed: "",
                                    loading: _vm.loading,
                                    disabled:
                                      !_vm.valid || _vm.token.length <= 0
                                  },
                                  on: { click: _vm.checkToken }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("login.submitResetPassword"))
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("v-card-title", { staticClass: "px-0" }, [
                        _vm._v("Inserisci la nuova passoword")
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": _vm.lazy },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.$t("profile.newPassword") +
                                            " *",
                                          "append-icon": _vm.showPassword
                                            ? "$eye"
                                            : "$eyeOff",
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                          rules: _vm.passwordRules,
                                          required: "",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          "click:append":
                                            _vm.toggleShowPassword,
                                          change: _vm.validate
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.$t("profile.passwordConfirm") +
                                            " *",
                                          "append-icon": _vm.showPassword
                                            ? "$eye"
                                            : "$eyeOff",
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                          rules: _vm.passwordConfirmRules,
                                          required: "",
                                          outlined: "",
                                          dense: ""
                                        },
                                        on: {
                                          "click:append":
                                            _vm.toggleShowPassword,
                                          change: _vm.validate
                                        },
                                        model: {
                                          value: _vm.passwordConfirm,
                                          callback: function($$v) {
                                            _vm.passwordConfirm = $$v
                                          },
                                          expression: "passwordConfirm"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "reset-button",
                              attrs: {
                                color: "primary",
                                large: "",
                                block: _vm.$vuetify.breakpoint.xs,
                                "min-width": "200",
                                height: "40",
                                depressed: "",
                                loading: _vm.loading
                              },
                              on: { click: _vm.handleResetPasswordConfirm }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("login.submitResetPassword"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "mt-2" },
                [_c("ResponseMessage", { attrs: { response: _vm.response } })],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }